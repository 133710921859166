import * as React from 'react';
import { graphql } from 'gatsby';
import CompanyFacts from '../components/company-facts';
import FancyTitle from '../components/fancy-title';
import Services from '../components/services';
import References from '../components/references';
import BlogGrid from '../components/blog-grid';
import Partners from '../components/partners';
import LayoutConsumer from '../components/layout-context';
import Seo from '../components/seo';
import SliderTestimonials from '../components/slider-testimonials';
import HeroVideo from '../components/hero-video';
import ScrollSlider from '../components/scroll-slider';
import PipedriveForm from '../components/pipedrive-form';

function IndexPage({ data }) {
  const { intro } = data.site.siteMetadata;

  const textImageScrollSliderData = data.site.siteMetadata.textImageScrollSlider
    .map((item, index) => {
      const length = data.site.siteMetadata.textImageScrollSlider.length - index;

      return {
        title: item.title,
        subtitle: item.subTitle,
        description: item.description,
        link: {
          url: item.link.url,
          text: item.link.text,
        },
        image: data[`imageTextSwiper${length}Image`],
      };
    });

  return (
    <LayoutConsumer>
      {({ set }) => {
        set({ pageTitle: '', fullWidth: true });
        return (
          <>
            <Seo title="Shopify Agentur & offizieller Shopify Partner" description="Wir sind offizieller Shopify Partner und Ihre Shopify Agentur für ✓ Shop-Erstellung ✓Design ✓ Programmierung & Migration" />
            <HeroVideo />
            <CompanyFacts />
            <div className="dark bg-background text-white py-28">
              <FancyTitle
                text="Wir sind Latori"
                className="text-center"
                tag="h2"
                subtitle="Ihre Agentur für Shopify und Shopify Plus"
                textAlign="text-center lg:text-center"
                marginBottom="mb-10"
              />
              <p className="m-auto text-center prose dark:prose-invert px-4">{intro.text}</p>
              <div className="text-latori-pink text-center text-3xl font-monospace italic mt-12 glow">{intro.claim}</div>
            </div>
            <div className="px-4 lg:px-16">
              <Services />
              {data.site.siteMetadata.textImageScrollSlider && (
                <div className="mt-[100px] lg:mt-60 scroll-slider-homepage">
                  <ScrollSlider
                    items={textImageScrollSliderData}
                    subheadlineMobile
                  />
                </div>
              )}

              <div className="mt-[100px] lg:mt-60">
                <References
                  title="Wen wir unterstützen"
                  subtitle="Referenzen & Shopify Projekte"
                  textAlign="text-center lg:text-center"
                  titleTag="h2"
                  referencesTitleTag="h3"
                  limit={15}
                />
              </div>
              <div className="mt-[100px] lg:mt-60">
                <SliderTestimonials />
              </div>
              <Partners />
              <div className="mt-[100px] lg:mt-60">
                <BlogGrid />
              </div>
              <div className="mt-[100px] lg:mt-60">
                <PipedriveForm
                  title="Lassen Sie uns sprechen!"
                  subtitle="Beratung durch Shopify-Experten"
                  text="Sie interessieren sich für eine Migration, möchten einen Shopify Shop erstellen lassen, brauchen eine eigene Shopify App oder wünschen sich Anpassungen und eine Optimierung an Ihrem Shopify Theme? Wir sind die richtige Shopify Agentur für Ihr Projekt. Egal wie komplex, unsere Experten unterstützen Sie mit 10-jähriger Erfahrung. Lassen Sie uns unverbindlich sprechen - schreiben Sie uns für eine kostenlose Erstberatung!"
                />
              </div>
              <div className="my-[100px] lg:my-60">
                <div className="items-center flex flex-col">
                  <FancyTitle
                    textAlign="text-center"
                    text={data.site.siteMetadata.seoTextSection.title}
                    subtitle={data.site.siteMetadata.seoTextSection.subtitle}
                    tag="h2"
                    className="my-10"
                  />
                  <div className="xl:columns-2">
                    {data.site.siteMetadata.seoTextSection.texts.map((paragraph) => (
                      <p key={paragraph} className="pb-4">{paragraph}</p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }}
    </LayoutConsumer>
  );
}

export default IndexPage;

// First image should get the highest index position,
// because it will be reversed for the scroll-slider

export const pageQuery = graphql`
  query {
    imageTextSwiper4Image:file(relativePath: {eq: "SUA19-latori.jpg"}) {
      childImageSharp {
        gatsbyImageData (
          width: 2000
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    imageTextSwiper3Image:file(relativePath: {eq: "SUA-Awards.jpg"}) {
      childImageSharp {
        gatsbyImageData (
          width: 2000
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    imageTextSwiper2Image:file(relativePath: {eq: "SUA-jan-caro.jpg"}) {
      childImageSharp {
        gatsbyImageData (
          width: 2000
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    imageTextSwiper1Image:file(relativePath: {eq: "SUA-Konfetti.jpg"}) {
      childImageSharp {
        gatsbyImageData (
          width: 2000
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    site {
      siteMetadata {
        textImageScrollSlider {
          title
          subTitle
          description
          link {
            url
            text
          }
        }
        seoTextSection {
          title
          subtitle
          texts
        }
        intro {
          title
          text
          claim
          link {
            url
            label
          }
        }
      }
    }
  }
`;
