import * as React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '../icons/icon-pack';
import FancyTitle from './fancy-title';
import SplitPane from './split-pane';

function Services() {
  library.add({ ...icons });

  const data = useStaticQuery(
    graphql`
      query {
        allServicesYaml {
          edges {
            node {
              name
              icon
              link
              slug
            }
          }
        }
      }
    `,
  );

  const { edges } = data.allServicesYaml;

  return (
    <div className='mt-[100px] lg:mt-32'>
      <SplitPane
        className="gap-20 lg:!flex-wrap xl:!flex-nowrap"
        left={(
          <div className="xl:text-right mx-auto xl:mx-0">
            <FancyTitle text="Was wir tun" subtitle="Leistungen als Shopify Agentur" tag="h2" marginBottom="mb-2" textAlign="text-center lg:text-left xl:text-right" />
          </div>
        )}
        right={(
          <div className="grid gap-4 grid-cols-2 lg:grid-cols-3">
            {
              edges.map((edge, index) => {
                const service = edge.node;

                return (
                  <Link to={`/services/${service.slug}`} className={`${index > 4 ? 'hidden lg:block' : 'block'} relative h-48 bg-green-800 hover:bg-green-900 group`} key={service.name}>
                    <div className="p-2 flex w-full h-full gap-4 flex-col justify-center items-center group-hover:scale-105 transition-transform text-center">
                      {service.icon
                        && <FontAwesomeIcon icon={icon({ prefix: 'far', iconName: service.icon })} size="2x" className="text-white" />}
                      <h4 className="text-lg text-white hyphens-auto" dangerouslySetInnerHTML={{ __html: service.name }} />
                    </div>
                  </Link>
                );
              })
            }
            <Link to="/services" className="flex p-12 bg-green-800 hover:bg-green-900 text-white group">
              <div className="flex gap-4 md:gap-2 flex-col md:flex-row m-auto items-center group-hover:scale-105 transition-transform">
                <span className="mr-2">Mehr</span>
                <FontAwesomeIcon className="-order-1 md:order-2" icon={icon({ prefix: 'far', iconName: 'arrow-right' })} size="2x" />
              </div>
            </Link>
          </div>
        )}
      />
    </div>
  );
}
export default Services;
