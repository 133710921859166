import * as React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import FancyTitle from './fancy-title';

const sliderLogoWidth = 144;

function PartnerLogo({ partners, partner, index }) {
  return (
    <div className={`pointer-events-none${index === partners.length - 1 ? ' pr-12' : ''}`}>
      {
        partner?.logo?.publicURL
          ? (
            <img
              src={partner.logo.publicURL}
              width={partner.logoWidth}
              height={partner.logoHeight}
              className="w-[var(--slider-logo-width)] max-h-12 invert dark:invert-0"
              style={{ '--slider-logo-width': `${sliderLogoWidth}px` }}
              alt={partner.title}
              loading="lazy"
            />
          )
          : (<h4>{partner.title}</h4>)
      }
    </div>
  );
}

function Partners() {
  const data = useStaticQuery(
    graphql`
      query {
        allPartnersYaml {
          edges {
            node {
              title
              logo {
                publicURL
                extension
              }
              logoWidth
              logoHeight
              url
            }
          }
        }
      }
    `,
  );

  const partners = data.allPartnersYaml.edges;
  const even = partners.filter((_, i) => i % 2 === 0);
  const odd = partners.filter((_, i) => i % 2 !== 0);
  const slideShowWidths = [
    even.length * (sliderLogoWidth + 32),
    odd.length * (sliderLogoWidth + 32),
  ];

  return (
    <div className="mt-[100px] lg:mt-60">
      <FancyTitle
        text="Auf diese Partner vertrauen wir"
        className="text-center my-10"
        tag="h2"
        subtitle="Ein starkes Netz aus E-Commerce-Experten"
        textAlign="lg:text-center"
        marginBottom="mb-20"
      />
      {[even, odd].map((slider, i) => (
        <div
          className="overflow-hidden slideshow my-8"
          style={{ '--slideshow-width': `-${slideShowWidths[i]}px` }}
          key={`slideshow-${slider[0].node.title}`}
        >
          <div className="slideshow-track flex flex-row">
            {[1, 2, 3].map((count) => (
              <div key={`track-loop-${count}`}>
                <div className="flex items-center gap-8" style={{ width: `${slideShowWidths[i]}px` }}>
                  {slider.map((edge, index) => {
                    const partner = edge.node;
                    const partnerLogo = (
                      <PartnerLogo
                        partners={partners}
                        partner={partner}
                        index={index}
                      />
                    );

                    return (
                      partner.url ? (
                        <Link to={partner.url} key={partner.title}>
                          {partnerLogo}
                        </Link>
                      ) : (
                        <div key={partner.title}>{partnerLogo}</div>
                      )
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Partners;
