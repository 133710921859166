import * as React from 'react';
import FancyTitle from '../components/fancy-title';
import Video from '../components/video';

function HeroVideo() {
  return (
    <div className="grid relative">
      <div className="relative">
        <Video
          desktopVideo={[
            { src: '/assets/videos/hero-video.webm', type: 'video/webm' },
            { src: '/assets/videos/hero-video.mp4', type: 'video/mp4' },
          ]}
        />
      </div>
      <div className="relative lg:absolute left-0 bottom-0">
        <div className="lg:m-8 p-4 lg:p-8 bg-latori-green-dark lg:bg-opacity-75 text-white lg:backdrop-blur relative z-30">
          <FancyTitle
            text="Shopify Agentur"
            tag="h1"
            fontSize="text-3xl lg:text-5xl"
            className="mb-4 lg:mb-7"
          />
          <h2 className="text-xl lg:text-3xl">Latori GmbH</h2>
        </div>
      </div>
    </div>
  );
}

export default HeroVideo;
