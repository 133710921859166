import * as React from 'react';
import { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useWindowSize } from './layout';

function Video({
  mobileVideo,
  desktopVideo,
  mobilePoster,
  desktopPoster,
}) {
  const windowSize = useWindowSize();

  const videoRef = useRef();

  useEffect(() => {
    if (videoRef.current) videoRef.current.playbackRate = 0.75;
  }, [videoRef]);

  return (
    <>
      {mobileVideo && (
        <video
          width="160"
          height="90"
          autoPlay
          muted
          loop
          playsInline
          ref={videoRef}
          className="w-screen aspect-video object-cover overflow-hidden md:hidden"
          poster={mobilePoster}
        >
          {windowSize.width < 768 && mobileVideo.map((video) => (
            <source
              key={video.src}
              src={video.src}
              type={video.type}
            />
          ))}
        </video>
      )}
      {desktopVideo && (
        <video
          width="160"
          height="90"
          autoPlay
          muted
          loop
          playsInline
          ref={videoRef}
          className={clsx('w-screen aspect-video object-cover', mobileVideo && 'hidden md:block')}
          poster={desktopPoster}
        >
          {(windowSize.width >= 768 || !mobileVideo) && desktopVideo.map((video) => (
            <source
              key={video.src}
              src={video.src}
              type={video.type}
            />
          ))}
        </video>
      )}
    </>
  );
}

export default Video;
