import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

function CompanyFacts() {
  const data = useStaticQuery(
    graphql`
      query {
        allCompanyFactsYaml {
          edges {
            node {
              label
              value
            }
          }
        }
      }
    `,
  );

  const facts = data.allCompanyFactsYaml.edges;

  return (
    <div>
      <div className="grid grid-cols-2 xl:grid-cols-4 my-10">
        {facts.map((edge, index) => {
          const fact = edge.node;
          return (
            <div className={`p-2 flex flex-wrap xl:flex-nowrap justify-center items-center ${index < facts.length - 1 ? 'xl-border-r-[inherit] xl:dark-border-r-white xl:border-r-2' : ''}`} key={fact.value}>
              <span className="basis-full xl:basis-auto text-center xl:text-left font-bold text-latori-green-dark text-5xl xl:mr-4">{fact.value}</span>
              <span className="basis-full xl:basis-auto text-center xl:text-left w-32" dangerouslySetInnerHTML={{ __html: fact.label }}></span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CompanyFacts;
