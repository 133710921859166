import * as React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FancyTitle from './fancy-title';
import * as icons from '../icons/icon-pack';

function BlogGrid() {
  library.add({ ...icons });

  const data = useStaticQuery(
    graphql`
      query {
        allStoryblokEntry(
          filter: {
            field_component: { eq: "blogpost" }
            lang: { eq: "default" }
          }
          limit: 8
          sort: { fields: first_published_at, order: DESC }
        ) {
          edges {
            node {
              id
              internalId
              uuid
              name
              content
              full_slug
              lang
              first_published_at
              slug
              tag_list
              translated_slugs {
                lang
                name
              }
            }
          }
        }
      }
    `,
  );

  const posts = data.allStoryblokEntry.edges.map((edge) => edge.node);

  return (
    <div>
      <FancyTitle
        text="Worüber wir bloggen"
        className="text-center my-10"
        tag="h2"
        marginBottom="mb-20"
        subtitle="Shopify News, Case Studies und E-Commerce-Tipps"
        textAlign="text-center lg:text-center"
      />

      <div className="mt-[60px] lg:mt-20 grid grid-cols-1 xl:grid-cols-3 md:grid-cols-2 gap-2 md:gap-4">
        {posts.map((post) => {
          const title = post.name || post.slug;
          let image = '';
          try {
            if (post.content) {
              image = JSON.parse(post.content).hero.filename;
            }
          } catch (error) {
            console.error(error);
          }
          return (
            <Link
              to={`/${post.full_slug}`}
              className="mx-auto aspect-[134/85] w-full relative group overflow-hidden"
              key={title}
            >
              <article
                itemScope
                itemType="http://schema.org/Article"
                className="bg-slate-100 dark:bg-latori-green-dark shadow-lg h-full"
              >
                {image && (
                  <img
                    alt={title}
                    src={image}
                    className="object-cover w-full h-full absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
                  />
                )}
                <div className="bg-latori-green-dark opacity-90 w-full h-full absolute bottom-0 left-0 transition-[height] group-hover:h-[0%]" />
                <div className="w-full h-full absolute top-0 left-0 flex flex-col justify-center items-start px-4 group-hover:opacity-0 transition-opacity text-white">
                  <h3 className="text-2xl" itemProp="headline">
                    {title}
                  </h3>
                </div>
              </article>
            </Link>
          );
        })}
        <Link
          to="/blog"
          className="flex p-12 bg-green-800 hover:bg-green-900 text-white group"
        >
          <div className="flex m-auto items-center group-hover:scale-105 transition-transform">
            <span className="mr-2">Mehr</span>
            <FontAwesomeIcon
              icon={icon({ prefix: 'far', iconName: 'arrow-right' })}
              size="2x"
            />
          </div>
        </Link>
      </div>
    </div>
  );
}

export default BlogGrid;
